$(function() {
  $("a[href*='#']:not([href='#'])").click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        if (window.location.href.indexOf("/updates/v") <= 0) {
          $('html,body').animate({
            scrollTop: target.offset().top - 68
          }, 1000);
        }
        else {
          $(window).scrollTop(target.offset().top - 68);
        }
        history.pushState(null, null, this.hash);
        $(target).get(0).focus();
        if ($(target).is(":focus")){
          return false;
        } else{
          $(target).attr('tabindex','-1');
          $(target).get(0).focus();
        };
        return false;
      }
    }
  });
});
