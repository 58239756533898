function adaptToBlogScroll(elementPosition, footerPosition, distanceFromText) {
  var scrollTop = $(window).scrollTop()
  var pointToRevertPosition = footerPosition.top - 900
  if (scrollTop > (pointToRevertPosition + elementPosition.top)) {
    $("main.blog-article-content header").css("position","absolute").css("top", pointToRevertPosition + "px").css("left", "initial").css("right", "0");
  } else if(scrollTop > elementPosition.top) {
    $("main.blog-article-content header").css("position","fixed").css("top", "0").css("left", ($("main .blog-article-content__text").offset().left + $("main .blog-article-content__text").width() + distanceFromText) + "px").css("right", "initial");
  } else {
    $("main.blog-article-content header").css("position","absolute").css("top", "0").css("left", "initial").css("right", "0");
  }
  $("main.blog-article-content header").width()
}

$(function () {
  if ($("#product-table-heading").length > 0) {
    var elementPosition = $(".product-page-nav").offset();
    var productTableHeadingPosition = $("#product-table-heading").offset();

    $(window).scroll(function(){
      var navWidth = $(".product-page-nav").width()
      var scrollTop = $(window).scrollTop()
      var productComparisonTopPadding = parseInt($("#product-comparison").css("padding-top"), 10)
      var pointToRevertPosition = (productTableHeadingPosition.top - productComparisonTopPadding)
      if(scrollTop > pointToRevertPosition) {
        $(".product-page-nav").css("position","absolute").css("top", productTableHeadingPosition.top + "px").addClass("remove-padding");
      } else if(scrollTop > elementPosition.top) {
        $(".product-page-nav").css("position","fixed").css("top","0").css("width",navWidth).removeClass("remove-padding");
      } else {
        $(".product-page-nav").css("position","static").removeClass("remove-padding");
      }

      // Scrollspy to select the section you're actually viewing
      $(".product-page-nav a.active").removeClass("active")
      $.each($(".product-page-nav a").get().reverse(), function(index, el) {
        var $link = $(el)
        var $el = $(el.hash); // Find the referenced container
        var position = $el.offset();

        if ( scrollTop >= position.top) {
          $link.addClass('active')
          return false
        }
      });
    });
  }

  //if ($("main.blog-article-content").length > 0) {
  //  var elementPosition = $("main.blog-article-content header").offset();
  //  var footerPosition = $("footer.site-footer").offset();
  //  var distanceFromText = elementPosition.position().left - $("main .blog-article-content__text").offset().position().left - $("main .blog-article-content__text").width();

  //  $(window).scroll(function(){
  //    adaptToBlogScroll(elementPosition, footerPosition, distanceFromText);
  //  });

  //  $(window).resize(function(){
  //    adaptToBlogScroll(elementPosition, footerPosition, distanceFromText);
  //  });

  //  adaptToBlogScroll(elementPosition, footerPosition, distanceFromText);
  //}
});
