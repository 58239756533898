function expandAccordion(el) {
  const $el = $(el);
  const $parent = $el.closest('.expander');
  const $content = $el.next('.expander-content');
  const isExpanded = $el.hasClass('expanded');

  $content.slideToggle('fast').toggleClass("expander-hidden");
  $el.toggleClass("expanded");

  $el.attr('aria-expanded', !isExpanded);
  $parent.toggleClass('is-open', !isExpanded);

  if ($el.attr('id') === 'drop') {
    $('body').toggleClass('menu-open', !isExpanded);
  }
}

function expandParentAccordion(el) {
  var $parent = $(el).closest('.expander'),
      $trigger = $parent.find('.expander-trigger'),
      $content = $parent.find('.expander-content');

  $content.slideToggle('fast').toggleClass("expander-hidden");
  $trigger.toggleClass("expanded");

  var ariaExpanded = $trigger.attr('aria-expanded') === 'true' ? 'false' : 'true';
  $trigger.attr('aria-expanded', ariaExpanded);
  $(el).attr('aria-label', ariaExpanded === 'true' ? 'collapse content' : 'expand content');
  $parent.toggleClass('is-open', ariaExpanded === 'true');
};

$(document).ready(function() {
  $('.expander-trigger').click(function(){
    expandAccordion(this);
  });
  $('.expander-arrow').click(function(){
    expandParentAccordion(this);
  });
});

// CHANGE PARENT ON CHILDREN HOVER

$(document).ready(function() {
  $(".children").hover(
    function() {
      $(this).closest(".parent").removeClass("over").addClass("over");
    }, function() {
      $(this).closest(".parent").removeClass("over");
    }
  );
});


// SIMPLE MODAL WINDOW

function simpleModalTrigger() {
  var $html = $(document.documentElement);
  var $navHolder = $('#holder');
  var htmlWidth = $html.innerWidth();
  $('html').toggleClass('modal-open');
  $html.css('paddingRight', ($html.css('paddingRight') ? '+=' : '') + ($html.innerWidth() - htmlWidth));
  $('#drift-widget').css('marginRight', ($('#drift-widget').css('marginRight') ? '+=' : '') + ($html.innerWidth() - htmlWidth));
  if($navHolder.css('position') == 'fixed') {
    $navHolder.css('paddingRight', ($navHolder.css('paddingRight') ? '+=' : '') + ($html.innerWidth() - htmlWidth));
  };
}

$(document).ready(function() {
  $('.simple-modal-wrapper:visible').click(function(e) {
    if (e.target !== this)
      return;
    $(this).closest('.simple-modal-parent').toggleClass('open');
    simpleModalTrigger();
    return false;
  });
  $('.modal-trigger').click(function() {
    $(this).closest('.simple-modal-parent').toggleClass('open');
    simpleModalTrigger();
    return false;
  });
});

$(document).ready(function() {
  if($('body').is('[class*="blog_"]')) {
    $('.blog-article-content__text > p, .blog-article-content__text > ul').addClass('pg-section');
  } else {
    if($('body').is('[class*="updates_"]')) {
      $('.updates-main-content > p, .updates-main-content > ul').addClass('pg-section');
    }
    else {
      return false;
    }
  }
});

// PLAY/PAUSE SLIDER TOGGLE
//(function($) {
//  $.fn.clickToggle = function(func1, func2) {
//    var funcs = [func1, func2];
//    this.data('toggleclicked', 0);
//    this.click(function() {
//      var data = $(this).data();
//      var tc = data.toggleclicked;
//      $.proxy(funcs[tc], this)();
//      data.toggleclicked = (tc + 1) % 2;
//    });
//    return this;
//  };
//}(jQuery));

var video_wrapper = $('.youtube-video-place'),
  placeholder_img = $('.play-youtube-video'),
  vids = [];
//if(video_wrapper.length){
//  placeholder_img.on('click', function(e){
//    var closest_wrapper = $(e).closest(video_wrapper);
//    swapVideo(e);
//    //clickConsent();
//    ga('send', 'event', 'YouTube', 'Activate', closest_wrapper.data('yt-title'));
//  });
//  video_wrapper.keyup(function (e) {
//    if (e.which == 13) {
//      placeholder_img.click();
//      return false;
//    }
//  });
//};
placeholder_img.on('click', function(e){
  //console.log(e);
  if ($('#youtube-api').length < 1) {
    loadYTapi();
  }
  var closest_wrapper = $(this).closest(video_wrapper);
  if (closest_wrapper.length) {
    swapVideo(e.currentTarget);
    //clickConsent();
    //if (typeof(ga) == 'function') {
    //  ga('send', 'event', 'YouTube', 'Activate', closest_wrapper.data('yt-title'));
    //} else {
    //  console.log("Activated Youtube player; Unable to access ga()");
    //}
    dataLayer.push({
      'event': 'eventTracking',
      'category': 'YouTube',
      'action': 'Activate',
      'label': closest_wrapper.data('yt-title')
    });
    dataLayer.push({
      'event': 'video_activate',
      'video_title': closest_wrapper.data('yt-title'),
      'video_url': 'https://www.youtube.com/watch?v=' + closest_wrapper.data('yt-id')
    });
  }
})
video_wrapper.keyup(function (e) {
  if (e.which == 13) {
    //console.log(e);
    $(this).find(placeholder_img).click();
    return false;
  }
});
//function onYouTubeIframeAPIReady() {
//  player = new YT.Player('player' + closest_wrapper.data('yt-id'), {
//    events: {
//      'onReady': onPlayerReady,
//      'onStateChange': onPlayerStateChange
//    }
//  });
//}
function swapVideo(e) {
  var closest_wrapper = $(e).closest(video_wrapper),
      player;
  function loadVideo() {
    closest_wrapper.removeAttr('role').attr('aria-label', 'Youtube video').html('<iframe id="player' + closest_wrapper.data('yt-id') + '" role="presentation" title="' + closest_wrapper.data('yt-title') + '" frameborder="0" allowfullscreen src="' + closest_wrapper.data('yt-url') + '&origin=' + window.location.origin + '"></iframe>');
    player = new YT.Player('player' + closest_wrapper.data('yt-id'), {
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
    vids.push(player);
  }
  if (typeof(YT) == 'undefined') {
    window.onYouTubeIframeAPIReady = function() {
      loadVideo();
    }
  }
  else {
    loadVideo();
  }
  //console.log(vids.toString());
}
function onPlayerReady(event) {
  console.log('load player ' + event.target.getVideoData()['video_id']);
  var current = event.target,
      playerState = current.getPlayerState();
  if (playerState != 1) {
    current.playVideo();
    //console.log('play video');
    pauseOtherVideos(current);
  }
  else {
    //console.log('video already playing');
    pauseOtherVideos(current);
  }
  //document.getElementById('player' + closest_wrapper.data('yt-id')).style.cssText = 'border: orange 2px solid';
}
//var done = false;
function pauseOtherVideos(e) {
  for (const i of vids) {
    if (i !== e && i.getPlayerState() === 1) {
      i.pauseVideo();
      //console.log('stopped ' + i.getVideoData()['video_id']);
    }
    //else {
    //  console.log('no other videos playing')
    //}
  }
}
function pauseAllVideos() {
  if (vids.length) {
    for (const i of vids) {
      if (i.getPlayerState() === 1) {
        i.pauseVideo();
      }
    }
  }
}
$('#live-tab').on('click', function(){
  pauseAllVideos();
});

function stopVideo() {
  player.stopVideo();
}

var ytSeconds = 0;
function onPlayerStateChange(e) {
  if (e.data == 1) {
    pauseOtherVideos(e.target);
  }
  if (e.data == 1 && ytSeconds > 0) {
    e.target.seekTo(ytSeconds);
    ytSeconds = 0;
  }
}
function seekTo(seconds) {
  if (player.getPlayerState() == 1) {
    player.seekTo(seconds);
  }
  else {
    ytSeconds = seconds;
    player.playVideo();
  }
}
$('.video-bookmark').on('click', function(){
  var bookmark = $(this).data('yt-timestamp');
  seekTo(bookmark);
});

window.loadmap = function (button) {
  var map_wrapper = $(button).closest('.location').find('.video-wrapper');
  map_wrapper.html('<iframe allowfullscreen="true" frameborder="0" height="405" src="' + map_wrapper.data('map-url') + '" style="border:0" title="' + map_wrapper.data('map-title') + '" width="720"></iframe>');
  //if (typeof(ga) == 'function') {
  //  ga('send', 'event', 'Maps', 'Load', map_wrapper.data('map-title'));
  //} else {
  //  console.log("Loaded map; Unable to access ga()");
  //}
  dataLayer.push({
    'event': 'eventTracking',
    'category': 'Maps',
    'action': 'Load',
    'label': map_wrapper.data('map-title')
  });
  dataLayer.push({
    'event': 'map_load',
    'label': map_wrapper.data('map-title')
  });
};

window.loadCSS = function(href) {
  var cssLink = $("<link>");
  $("head").append(cssLink); //IE hack: append before setting href
  cssLink.attr({
    rel:  "stylesheet",
    type: "text/css",
    href: href
  });
};

$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(window).on('resize scroll', function() {
  $('.pg-section').each(function() {
    if ($(this).isInViewport()) {
      $(this).addClass('section-active');
    } else {
      $(this).removeClass('section-active');
    }
  });
});

// add passive listener
//jQuery.event.special.touchstart = { setup: function( _, ns, handle ){ if ((ns.indexOf('noPreventDefault') > -1)) { this.addEventListener("touchstart", handle, { passive: false }); } else { this.addEventListener("touchstart", handle, { passive: true }); } } };

/* randomize visibility */
var randomItem = Math.floor(Math.random() * $('.randomize').length);
$('.randomize').hide().removeClass('chosen').eq(randomItem).show().addClass('chosen');

// make code blocks accessible by keyboard
var codeblocks = document.querySelectorAll('pre');

function makeScrollableBlocksFocusable() {
  //console.log("making scrollable blocks focusable");

  for (var i=0; i < codeblocks.length; i++) {
    if (isOverflown(codeblocks[i])) {
      codeblocks[i].setAttribute("tabindex", "0");
    } else {
      codeblocks[i].removeAttribute("tabindex");
    }
  }
}

function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

window.addEventListener("load", (event) => {
  if (codeblocks.length > 0) {
    makeScrollableBlocksFocusable();
    window.addEventListener("resize", makeScrollableBlocksFocusable);
  }
  else {
    console.log("no code blocks on page");
  }
});

// github stars
$('[data-github]').each(function () {
  var _this = this;
  var repo = $(_this).data('github');
  var starNum = getCookie('stargazers');

  if (starNum == null) {
    fetch('https://api.github.com/repos/' + repo).then(function (response) {
      return response.json();
    }).then(function (response) {
      $(_this).find('[data-stars]').text(numberWithCommas(response.stargazers_count));
      setCookie('stargazers', response.stargazers_count, 1/24);
    });
  }
  else {
    $(_this).find('[data-stars]').text(numberWithCommas(starNum));
  }
});

function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function loadYTapi() {
  var yttag = document.createElement('script'),
    firstScriptTag = document.getElementsByTagName('script')[0];
  yttag.id = 'youtube-api';
  yttag.src = 'https://www.youtube.com/iframe_api';
  if($('.youtube-video-place').length){
    firstScriptTag.parentNode.insertBefore(yttag, firstScriptTag);
    console.log("loading youtube api");
  };
}