var aTabsMin = $('.accordion-tabs-minimal'),
    aTabsMinHeight = $(aTabsMin).innerHeight();

$(document).ready(function () {
  $(window).resize(function(){
    $(aTabsMin).find('a.tab-link').unbind('click');
    useCaseTabAnimationBind();
  });
  useCaseTabAnimationBind();

  tabsAnimation();
});
function useCaseTabAnimationBind() {
  var oTabCon = $(aTabsMin).find('.is-open'),
      tabLinks = $(aTabsMin).find('.tab-link');
  if($('.device-mode-flag').is(':visible')) {
    if (oTabCon.length > 0){
      oTabCon.slideDown(500).prev('a').addClass('is-active').attr('aria-selected', 'true').promise().done(function(){
        tabLinks.each(function(){
          if (!$(this).hasClass('is-active')) {
            $(this).attr('aria-selected','false')
          }
        })
      });
    }
    else {
      tabLinks.each(function(){
        if (!$(this).hasClass('is-active')) {
          $(this).attr('aria-selected','false')
        }
      })
    }
    tabLinks.on('click', function(event) {
      var btn = $(this);
      var needOpen = !btn.hasClass('is-active');
      btn.closest('.accordion-tabs-minimal').find('.is-open').slideUp(500).promise().done(function() {
        $(this).removeClass('is-open');
        $(this).prev('.is-active').removeClass('is-active').attr('aria-selected', 'false');

        if (needOpen) {
          btn.next('div').toggleClass('is-open').children().first().show().parent().slideDown(500).promise().done(function(){
            btn.toggleClass('is-active').attr('aria-selected',$(this).attr('aria-selected')==='false'?'false':'true' );
            if ($('body').hasClass('product')) {
              var topOffsetHeight = $('#holder').height() + $('.products-subnav').height();
            }
            else {
              if ($('#holder').length < 1) {
                if ($('body').hasClass('cituscon') && $('#topbar').length > 0) {
                  var topOffsetHeight = $('#topbar').height();
                }
                else {
                  var topOffsetHeight = 0;
                }
              }
              else {
                var topOffsetHeight = $('#holder').height();
              }
            };
            $('html, body').animate({
              scrollTop: btn.offset().top - topOffsetHeight
            }, 500);
            console.log('offset: ' + topOffsetHeight);
          });
        }
      });
      return false;
    });
  }
  else {
    function accordionTabsHeight() {
      var openedTab = $('.fitted-tabs .is-open'),
          openedTabHeight = $(openedTab).innerHeight(),
          tabsFullHeight = aTabsMinHeight + openedTabHeight;
      $(openedTab).closest('.tabs-parent').css('height', tabsFullHeight + 'px');
    }
    if (oTabCon.length > 0){
      oTabCon.show().children().first().fadeIn(400, function() {
        oTabCon.prev('.tab-link').addClass('is-active').attr('aria-selected', 'true').parent().siblings().find('.tab-link').attr('aria-selected','false');
        if ($(aTabsMin).hasClass('fitted-tabs')) {
          accordionTabsHeight();
          window.addEventListener("resize", accordionTabsHeight);
        }
        else {
          return false;
        }
      });
    }
    else{
      tabLinks.first().addClass('is-active').attr('aria-selected', 'true').next().addClass('is-open').show().children().first().fadeIn().promise().done(function(){
        tabLinks.each(function(){
          if (!$(this).hasClass('is-active')) {
            $(this).attr('aria-selected','false')
          }
        })
      });
    }
    tabLinks.on('click', function(event) {
      var btn = $(this);
      if (!btn.hasClass('is-active')) {
        btn.closest('.accordion-tabs-minimal').find('.is-open').children().first().fadeOut(300).promise().done(function() {
          $(this).parent().removeClass('is-open').hide().prev('.is-active').removeClass('is-active').attr('aria-selected', 'false');
          btn.addClass('is-active').attr('aria-selected', 'true').next('.tab-content').addClass('is-open').show().children().first().fadeIn(400);
          if ($(aTabsMin).hasClass('fitted-tabs')) {
            accordionTabsHeight();
            window.addEventListener("resize", accordionTabsHeight);
          }
          else {
            return false;
          }
        });
        if (btn.is('[href^="#"')) {
          setTimeout(function () {
            if (window.location.hash != btn.attr('href')){
              window.location.hash = btn.attr('href');
            }
          }, 300);
        }
      };
      return false;
    });
  }
}

function tabsAnimation() {
  if ($('.tabs-minimal').find('div.is-open').length == 0){
    $('.tabs-minimal').find('a.tab-link').first().addClass('is-active').attr({'aria-selected':'true', 'selected':''}).next().addClass('is-open').show().children().first().fadeIn();
  }
  else{
    $('.tabs-minimal').find('div.is-open').show().children().first().fadeIn();
  }
  $('.tabs-minimal').find('a.tab-link').on('click', function(event) {
    if (!$(this).hasClass('is-active')) {
      var btn = $(this);
      btn.closest('.tabs-minimal').find('.is-open').children().first().fadeOut('fast').promise().done(function() {
        $(this).parent().removeClass('is-open').hide();
        $(this).parent().prev('a.is-active').removeClass('is-active').removeAttr('selected').attr('aria-selected', 'false');
        btn.addClass('is-active').attr({'aria-selected':'true', 'selected':''}).next('div').addClass('is-open').show().children().first().fadeIn('fast');
      });
    };
    return false;
  });
}