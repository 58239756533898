/* @flow */

//import React from 'react';
//import ReactDOM from 'react-dom';

// Global javascript
//import 'slick-carousel';
//import './global/slick-amended';
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';
import './global';
//import Cookies from 'js-cookie';
//window.Cookies = Cookies;

// Top-level components
//import Pricing from './components/Pricing';
//
//const topLevelComponents = { Pricing };
//
//window.renderTopLevelComponent = (id, name, props) => {
//  ReactDOM.render(
//    React.createElement(topLevelComponents[name], props),
//    document.getElementById(id)
//  );
//};

//const fetch = require("node-fetch");
let fetch;
if (typeof window === "undefined") {
  // Node.js environment
  fetch = require("node-fetch");
} else {
  // Browser environment
  fetch = window.fetch;
}

//module.exports = async function() {
//  console.log( "Fetching new github stargazers count…" );
//
//  // GitHub API: https://developer.github.com/v3/repos/#get
//  return fetch("https://api.github.com/repos/citusdata/citus")
//    .then(res => res.json()) // node-fetch option to transform to json
//    .then(json => {
//      // prune the data to return only what we want
//      return {
//        stargazers: json.stargazers_count
//      };
//    });
//};
